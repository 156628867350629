import React from "react";
import { compose } from "recompose";

import Layout from "../components/layout";
import Admin from "../components/Admin/Admin";
import {
  withAuthorization,
  withEmailVerification,
} from "../components/Account/Session";
import { ROLES } from "../constants";

const condition = (authUser) =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(Admin);

export default ({ location }) => (
  <Layout location={location}>
    <AdminPage location={location} />
  </Layout>
);
