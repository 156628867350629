import React, { Component } from "react";

import { withFirebase } from "../Firebase";

class UserList extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.users().on("value", (snapshot) => {
        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject).map((key) => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          users: usersList,
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h3>Site registraties:</h3>
        {loading && <div>Loading ...</div>}
        <ul>
          {users.map((user) => (
            <li key={user.uid}>
              <p>
                <strong>Naam:</strong> {user.username}
                {user.roles && (
                  <span> ( {Object.keys(user.roles)[0]} )</span>
                )}
              </p>
              <p>
                <strong>E-mail:</strong> {user.email}
              </p>
              <p>
                <strong>ID:</strong> {user.uid}
              </p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withFirebase(UserList);
