import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { ROUTES } from "../../constants";
import UserList from "./UserList";
import { StyledWhitePage } from "../Record/styles";

const StyledAdmin = styled.div`
  background-color: white;
  margin: -1rem;
  padding: 1rem;
`;

const Admin = ({ location }) => {
  const [triggerStarted, setTriggerStarted] = useState(false);

  // Example POST method implementation:
  const postData = async (url = "", data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response;
  };

  const refreshLocalStorage = () => {
    localStorage.removeItem("localRecords");
    localStorage.removeItem("lastUpdated");
    alert(
      "Cache verwijderd. Open nu de Singles pagina om de laatste wijzigingen te laden.",
    );
  };

  return (
    <StyledWhitePage>
      <h1>DirkKater.nl beheer</h1>
      <h3>Opties:</h3>
      <Link to={ROUTES.ADD_RECORD}>
        <button>Single toevoegen</button>
      </Link>
      <div>
        <h3>Site optimalisatie:</h3>
        <p>
          Alle pagina's op DirkKater.nl zijn geoptimaliseerd om zo
          snel mogelijk te laden. Wanneer je singles hebt gewijzigd of
          toegevoegd, moet deze optimalisatie opnieuw worden
          uitgevoerd, d.m.v. onderstaande knop. Dit duurt ongeveer
          5-10 minuten en hoeft niet na IEDERE single, alleen wanneer
          je klaar bent met alle wijzigen.
        </p>
        <button
          onClick={() => {
            postData(process.env.GATSBY_BUILD_HOOK_URL).then(() => {
              setTriggerStarted(true);
              localStorage.removeItem("localRecords");
            });
          }}
          disabled={triggerStarted}
        >
          {triggerStarted ? "Build begonnen" : "Site build starten!"}
        </button>
        &nbsp;
        <button onClick={refreshLocalStorage}>
          Cache versie verwijderen
        </button>
        <p>
          <img
            src="https://api.netlify.com/api/v1/badges/69a8f164-f1a0-46f5-aafb-d26b59843165/deploy-status"
            alt="Netlify status"
          />
        </p>
      </div>
      <hr />
      <h3>Mark's To Do List:</h3>
      <div>
        <input type="checkbox" /> Custom fields voor Albums
      </div>
      <div>
        <input type="checkbox" /> Discogs synchronisatie
      </div>
      <div>
        <input type="checkbox" /> Playlist per bezoeker (met favoriete
        singles)
      </div>
      <div>
        <input type="checkbox" /> Tests
      </div>
      <UserList />
    </StyledWhitePage>
  );
};

export default Admin;
